import React, { createContext, useContext, useState, useMemo } from 'react';
import { DIMENSION_TABS } from '../constants';

const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
    // Permanent filter states
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedBuyer, setSelectedBuyer] = useState('');
    const [selectedImporter, setSelectedImporter] = useState('');
    const [etdStartDate, setEtdStartDate] = useState(null);
    const [etdEndDate, setEtdEndDate] = useState(null);
    const [activeTab, setActiveTab] = useState(DIMENSION_TABS.MONTHLY);

    // Temporary filter states
    const [tempSelectedStatuses, setTempSelectedStatuses] = useState([]);
    const [tempSelectedBuyer, setTempSelectedBuyer] = useState('');
    const [tempSelectedImporter, setTempSelectedImporter] = useState('');
    const [tempEtdStartDate, setTempEtdStartDate] = useState(null);
    const [tempEtdEndDate, setTempEtdEndDate] = useState(null);

    const applyFilters = () => {
        setSelectedStatuses(tempSelectedStatuses);
        setSelectedBuyer(tempSelectedBuyer);
        setSelectedImporter(tempSelectedImporter);
        setEtdStartDate(tempEtdStartDate);
        setEtdEndDate(tempEtdEndDate);
    };

    const resetTempFilters = () => {
        setTempSelectedStatuses(selectedStatuses);
        setTempSelectedBuyer(selectedBuyer);
        setTempSelectedImporter(selectedImporter);
        setTempEtdStartDate(etdStartDate);
        setTempEtdEndDate(etdEndDate);
    };

    const value = useMemo(() => ({
        // Permanent states
        selectedStatuses,
        setSelectedStatuses,
        selectedBuyer,
        setSelectedBuyer,
        selectedImporter,
        setSelectedImporter,
        etdStartDate,
        setEtdStartDate,
        etdEndDate,
        setEtdEndDate,
        activeTab,
        setActiveTab,
        // Temporary states
        tempSelectedStatuses,
        setTempSelectedStatuses,
        tempSelectedBuyer,
        setTempSelectedBuyer,
        tempSelectedImporter,
        setTempSelectedImporter,
        tempEtdStartDate,
        setTempEtdStartDate,
        tempEtdEndDate,
        setTempEtdEndDate,
        // Actions
        applyFilters,
        resetTempFilters
    }), [
        selectedStatuses, selectedBuyer, selectedImporter, etdStartDate, etdEndDate, activeTab,
        tempSelectedStatuses, tempSelectedBuyer, tempSelectedImporter, tempEtdStartDate, tempEtdEndDate
    ]);

    return (
        <AnalyticsContext.Provider value={value}>
            {children}
        </AnalyticsContext.Provider>
    );
};

export const useAnalytics = () => {
    const context = useContext(AnalyticsContext);
    if (!context) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }
    return context;
};