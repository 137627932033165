import React from 'react';
import { Stack, Chip } from '@mui/material';

export const ViewType = {
    Basic: 'basic',
    Margin: 'margin',
    Payments: 'payments',
    Full: 'full',
    All: 'all'
};

export const getViewSettings = (viewType) => {
    switch (viewType) {
        case ViewType.Basic:
            return {
                total_cost_usd: false,
                gross_product_margin: false,
                total_paid: false,
                balance: false,
                total_additional_costs: false,
            };
        case ViewType.Margin:
            return {
                total_paid: false,
                total_additional_costs: false,
                balance: false,
            };
        case ViewType.Payments:
            return {
                total_cost_usd: false,
                gross_product_margin: false,
            };
        case ViewType.Full:
            return {
                // Show all columns
                total_cost_usd: true,
                gross_product_margin: true,
                total_paid: true,
                balance: true,
                total_additional_costs: true,
            };
        case ViewType.All:
            return {}; // User-defined visibility
        default:
            console.warn(`Unknown view type: ${viewType}`);
            return {};
    }
};

const ViewControls = ({ viewType, onViewTypeChange }) => {
    const handleViewTypeChange = (type) => {
        onViewTypeChange(viewType === type ? ViewType.All : type);
    };

    return (
        <Stack
            direction="row"
            spacing={1}
            sx={{
                mb: 2,
                justifyContent: 'flex-end'
            }}
        >
            {Object.values(ViewType).map((type) => (
                type !== ViewType.All && (
                    <Chip
                        key={type}
                        label={`${type.charAt(0).toUpperCase() + type.slice(1)} View`}
                        color={viewType === type ? 'primary' : 'default'}
                        onClick={() => handleViewTypeChange(type)}
                        variant={viewType === type ? 'filled' : 'outlined'}
                    />
                )
            ))}
        </Stack>
    );
};

export default ViewControls;