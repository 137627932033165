import React from "react";
import { useFetchData } from 'services/queries';
import { useEffect, useState, useContext } from "react";
import Card from 'components/card';
import Box from '@mui/material/Box';
import {
    DataGridPremium,
} from '@mui/x-data-grid-premium';
import useAuth from "hooks/useAuth";
import { Link } from "react-router-dom";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { GridActionsCellItem } from '@mui/x-data-grid';
import useRoleBasedColumns from 'hooks/useRoleBasedColumns';
import { BsFillEyeFill } from "react-icons/bs";
import DeleteConfirmDialog from 'components/dialog/DeleteConfirmDialog';
import ToolbarCrud from 'components/toolbar/ToolbarCrud';
import ToolbarSearch from 'components/toolbar/ToolbarSearch';
import { TbFileInvoice } from "react-icons/tb";
import { API_BASE_URL } from "config";
import { downloadFile } from 'utils/downloadFile';
import toast from 'react-hot-toast';


const ClientView = () => {
    const resource = 'order/sales-order';
    const resource_name = 'Sales Order';
    const { data: data, error: errorData, isLoading: isLoadingData } = useFetchData('order/simple-sales-order', true);
    // load delivery data
    const { data: users, error: errorUsers, isLoading: isLoadingUsers } = useFetchData('authentication/users', true);
    const { auth, authLoading } = useAuth();
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const [status, setStatus] = useState(null);

    useEffect(() => {
        // get all status from the data
        if (data) {
            const statusList = data.map(item => item.status);
            const uniqueStatus = [...new Set(statusList)];
            setStatus(uniqueStatus);
        }
    }, [data]);

    const columnsData = [
        {
            field: 'order_identifier',
            headerName: 'Reference',
            // width: 120,
            editable: false,
            groupable: false,
            aggregable: false,
            type: 'string',
        },
        {
            field: 'customer_ref',
            headerName: 'Customer Ref.',
            // width: 160,
            editable: false,
            groupable: false,
            aggregable: false,
            type: 'string',
        },
        {
            field: 'social_reason',
            headerName: 'Customer',
            // width: 400,
            editable: false,
            groupable: true,
            aggregable: false,
            type: 'string',
            flex: 1,
        },
        {
            field: 'issue_date',
            headerName: 'Date of Issue',
            editable: false,
            groupable: false,
            aggregable: false,
            type: 'date',
            valueGetter: (value, row) => {
                const issueDate = row?.issue_date || null;
                return issueDate ? new Date(issueDate) : null;
            },
        },
        {
            field: 'total_amount',
            headerName: 'Total Amount ($)',
            minWidth: 150,
            editable: false,
            groupable: false,
            aggregable: true,
            type: 'number',
            valueGetter: (value, row) => {
                const total_amount = row?.total_amount_invoice || 0;
                return parseFloat(total_amount);
            },
            renderCell: (params) => {
                const value = params.value;
                return (
                    <div>
                        {value.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </div>
                );
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 180,
            editable: false,
            groupable: true,
            aggregable: false,
            type: 'singleSelect',
            valueOptions: status?.map((status) => ({
                value: status,
                label: status,
            })),
        },
        // issue date - today
        {
            field: 'days',
            headerName: 'Time in Days',
            // width: 150,
            editable: false,
            groupable: false,
            aggregable: true,
            type: 'number',
            valueGetter: (value, row) => {
                const issueDate = row?.issue_date || null;
                const today = new Date();
                const diffTime = Math.abs(today - new Date(issueDate));
                const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                return diffDays;
            },
        },
        {
            field: 'etd',
            headerName: 'ETD',
            editable: false,
            groupable: false,
            aggregable: false,
            type: 'date',
            valueGetter: (value, row) => {
                const etd = row?.etd || null;
                return etd ? new Date(etd + "T12:00:00") : null;
            },
        },
        // delivery info . eta (date)
        {
            field: 'eta',
            headerName: 'ETA',
            editable: false,
            groupable: false,
            aggregable: false,
            type: 'date',
            valueGetter: (value, row) => {
                const eta = row?.eta || null;
                return eta ? new Date(eta + "T12:00:00") : null;
            },
        },
        // link to download the invoice
        {
            field: 'invoice',
            headerName: 'Invoice',
            type: 'actions',
            editable: false,
            groupable: false,
            aggregable: false,
            visibleTo: ['Client Administrator', 'Client Staff'],
            getActions: (params) => {
                const { id } = params.row;
                return [
                    <GridActionsCellItem
                        icon={<TbFileInvoice />}
                        color="primary"
                        label="Edit"
                        onClick={async () => {
                            try {
                                await downloadFile(`${API_BASE_URL}/order/generate-pdf-commercial-invoice/${id}/`);
                            } catch (error) {
                                const errorData = await error.json();
                                console.error(errorData);
                                toast.error(errorData.error || 'Failed to download the invoice.');
                            }
                        }}
                    />,
                    <GridActionsCellItem
                        icon={<BsFillEyeFill />}
                        color="primary"
                        label="View"
                        component={Link}
                        to={`/admin/${resource}/detail/${id}`}
                    />,
                    <GridActionsCellItem
                        icon={<BsPencilSquare />}
                        color="primary"
                        label="Edit"
                        component={Link}
                        to={`/admin/${resource}/edit/${id}`}
                    />,
                ]
            }
        },

        {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            editable: false,
            groupable: false,
            aggregable: false,
            visibleTo: ['Administrator', 'Staff'],
            getActions: (params) => {
                const { id } = params.row;
                return [
                    <GridActionsCellItem
                        icon={<BsPencilSquare />}
                        color="primary"
                        label="Edit"
                        component={Link}
                        to={`/admin/${resource}/edit/${id}`}
                    />,
                    <GridActionsCellItem
                        icon={<BsFillEyeFill />}
                        color="primary"
                        label="View"
                        component={Link}
                        to={`/admin/${resource}/detail/${id}`}
                    />,
                    <GridActionsCellItem
                        color="primary"
                        icon={<BsTrash />}
                        label="Delete"
                        onClick={() => {
                            setSelectedItem(params.row.id); // Set the selected item
                            setOpenDeleteDialog(true); // Open the dialog
                        }}
                    />,
                ];
            }
        },
        {
            field: 'assigned_to',
            headerName: 'Assigned To',
            editable: false,
            groupable: true,
            aggregable: false,
            minWidth: 150,
            valueGetter: (value, row) => {
                const user = users?.find(user => user.id === value);
                return user?.name || null;
            }
        }
    ];
    const columns = useRoleBasedColumns(columnsData);

    if (isLoadingData || authLoading || isLoadingUsers) return <div>Loading...</div>;
    if (errorData || errorUsers) return <div>Error! {errorData.message}</div>;

    return (
        <Card extra={'w-full h-full bg-white mt-3 p-3'}>
            <Box>
                <DataGridPremium
                    rows={data}
                    columns={columns.map(column => ({
                        ...column,
                        editable: false
                    }))}
                    pagination
                    pageSizeOptions={[25, 50, 100]}
                    initialState={{
                        density: 'comfortable',
                        pinnedColumns: { left: ['actions', 'invoice'] },
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    autosizeOptions={{
                        columns: ['reference', 'customer_ref', 'buyer', 'issue_date', 'total_amount', 'status', 'days'],
                        includeOutliers: true,
                        includeHeaders: true,
                    }}
                    slots={{
                        toolbar: () => {
                            return (
                                <ToolbarCrud
                                    resource={resource}
                                    name={resource_name}
                                />
                            )
                        }
                    }}
                />
            </Box>
            <DeleteConfirmDialog
                openDeleteDialog={openDeleteDialog}
                setOpenDeleteDialog={setOpenDeleteDialog}
                selectedItem={selectedItem}
                resource={resource}
            />
        </Card>

    )
}

export default ClientView