import React from 'react';
import { Paper, useTheme, Typography } from '@mui/material';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatCompactCurrency } from '../../../../../../../../utils/formatters';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <Paper sx={{ p: 1.5, boxShadow: 2 }}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>{label}</Typography>
                {payload.map((entry, index) => (
                    <Typography key={index} variant="body2" sx={{ color: entry.stroke }}>
                        {entry.name}: {formatCompactCurrency(entry.value)}
                    </Typography>
                ))}
            </Paper>
        );
    }
    return null;
};

const CumulativePerformanceChart = ({ data }) => {
    const theme = useTheme();

    return (
        <Paper sx={{ p: 2, height: 400 }}>
            <Typography variant="h6" component="h2" sx={{ mb: 2, textAlign: 'center', fontWeight: 'medium' }}>
                Cumulative Financial Performance
            </Typography>
            <ResponsiveContainer width="100%" height="90%">
                <AreaChart
                    data={data}
                    margin={{ top: 10, right: 30, left: 0, bottom: 20 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="monthYear" angle={-45} textAnchor="end" height={50} />
                    <YAxis tickFormatter={formatCompactCurrency} width={100} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend verticalAlign="bottom" height={36} wrapperStyle={{ paddingTop: '20px' }} />
                    <Area type="monotone" dataKey="cumulative_invoice" stroke={theme.palette.primary.main} fill={theme.palette.primary.light} name="Invoice" stackId="1" />
                    <Area type="monotone" dataKey="cumulative_product_purchase_cost" stroke={theme.palette.error.main} fill={theme.palette.error.light} name="Purchase" stackId="2" />
                    <Area type="monotone" dataKey="cumulative_margin" stroke={theme.palette.success.main} fill={theme.palette.success.light} name="Margin" stackId="3" />
                </AreaChart>
            </ResponsiveContainer>
        </Paper>
    );
};

export default CumulativePerformanceChart;