import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';

const AnalyticsTabs = React.memo(({ activeTab, onTabChange }) => (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={activeTab} onChange={onTabChange}>
            <Tab label="By Month" />
            <Tab label="By Client" />
            <Tab label="By Product" />
            <Tab label="By Category" />
            <Tab label="By Importer" />
            <Tab label="Custom" />
        </Tabs>
    </Box>
));

export default AnalyticsTabs;