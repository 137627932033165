import React from 'react';
import { Autocomplete, TextField, FormControl } from '@mui/material';
import { useFetchData } from 'services/queries';
import { useAnalytics } from '../../context/analyticsContext';

const BuyerFilter = () => {
    const { tempSelectedBuyer, setTempSelectedBuyer } = useAnalytics();
    const { data: buyerList, error: buyerError, isLoading: buyerLoading } = useFetchData('client/', true);

    if (buyerLoading) return <div>Loading...</div>;
    if (buyerError) return <div>Error!</div>;

    const selectedBuyerObject = buyerList?.find(buyer => buyer.id === tempSelectedBuyer) || null;

    return (
        <FormControl sx={{ width: 300, mb: 3, ml: 2 }}>
            <Autocomplete
                value={selectedBuyerObject}
                onChange={(_, newValue) => setTempSelectedBuyer(newValue?.id || '')}
                options={buyerList || []}
                getOptionLabel={(option) => option.social_reason || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Client Filter"
                        placeholder="Select a client"
                    />
                )}
                loading={buyerLoading}
                loadingText="Loading clients..."
                noOptionsText="No clients found"
                clearText="Clear"
            />
        </FormControl>
    );
};

export default BuyerFilter;