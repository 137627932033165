import React from 'react';
import { Paper, Box, useTheme, Typography } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { formatCompactCurrency as formatCurrency, formatPercentage, formatMonth } from '../../../../../../../../utils/formatters';

const MarginAnalysisGrid = ({ data }) => {
    const theme = useTheme();

    const columns = [
        { field: 'year', headerName: 'Year', minWidth: 75, flex: 1 },
        { field: 'month', headerName: 'Month', width: 95, valueFormatter: (value) => formatMonth(value) },
        { field: 'invoice', headerName: 'Invoice', width: 130, valueFormatter: (value) => formatCurrency(value) },
        {
            field: 'invoice_mom',
            headerName: 'Invoice MoM',
            description: 'Month-over-Month Invoice Growth. Formula: ((current_invoice - previous_invoice) / previous_invoice) × 100',
            width: 130,
            renderCell: (params) => (
                <Box sx={{ color: params.value >= 0 ? theme.palette.success.main : theme.palette.error.main }}>
                    {formatPercentage(params.value)}
                </Box>
            ),
        },
        {
            field: 'product_purchase_cost',
            headerName: 'Purchase',
            width: 130,
            valueFormatter: (value) => formatCurrency(value),
        },
        {
            field: 'product_purchase_cost_mom',
            headerName: 'Purchase MoM',
            description: 'Month-over-Month Purchase Growth. Formula: ((current_purchase - previous_purchase) / previous_purchase) × 100',
            width: 130,
            renderCell: (params) => (
                <Box sx={{ color: params.value >= 0 ? theme.palette.error.main : theme.palette.success.main }}>
                    {formatPercentage(params.value)}
                </Box>
            ),
        },
        {
            field: 'margin',
            headerName: 'Margin',
            width: 130,
            valueFormatter: (value) => formatCurrency(value),
        },
        {
            field: 'margin_mom',
            headerName: 'Margin MoM',
            description: 'Month-over-Month Margin Growth. Formula: ((current_margin - previous_margin) / previous_margin) × 100',
            width: 130,
            renderCell: (params) => (
                <Box sx={{ color: params.value >= 0 ? theme.palette.success.main : theme.palette.error.main }}>
                    {formatPercentage(params.value)}
                </Box>
            ),
        },
        {
            field: 'margin_percentage',
            headerName: 'Margin %',
            width: 130,
            renderCell: (params) => (
                <Box>
                    {formatPercentage(params.value)}
                </Box>
            ),
        },
    ];

    // disable gruppable, aggregable for all columns
    columns.forEach((column) => {
        column.editable = false;
        column.groupable = false;
        column.aggregable = false;
    });

    return (
        <Paper sx={{ p: 2, height: 800 }}>
            <DataGridPremium
                rows={data.map((item, index) => ({ ...item, id: index }))}
                columns={columns}
                initialState={{
                    sorting: {
                        sortModel: [
                            { field: 'year', sort: 'desc' },
                            { field: 'month', sort: 'desc' }
                        ],
                    },
                }}
                pageSizeOptions={[10, 25, 50]}
                disableRowSelectionOnClick
            />
        </Paper>
    );
};

export default MarginAnalysisGrid;